// import axiosConfig from "@/config/axiosConfig";

import { AxiosResponse } from "axios";
import { axiosInstance as axios, axiosInstance } from "@/plugins/axios";

export class AgreementApi {
  public static baseUrl: string = "/api/agreements";

  public static async create(data: any): Promise<AxiosResponse> {
    return await axiosInstance.post(`${this.baseUrl}/`, data);
  }

  public static async find(id?: number): Promise<AxiosResponse> {
    const url = id ? `${this.baseUrl}/${id}` : `${this.baseUrl}`;
    return await axiosInstance.get(url);
  }

  static async update(
    command: {
      certificates: Record<string, unknown>[];
      volumes: Record<string, unknown>[];
      detail: Record<string, unknown>;
      financialInstruments: Record<string, unknown>[];
    },
    id: number
  ) {
    return await axiosInstance.post(`${this.baseUrl}/update/${id}`, command);
  }

  public static async list(query: string) {
    const response = await axios.get(AgreementApi.baseUrl + "/list?" + query);
    return response;
  }
}
